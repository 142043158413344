.button {
  font-size: var(--font-button);
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  padding: var(--spacing-sm);
}

.button:hover {
  transition: background-color 0.3s;
}
