.banner {
  position: relative;
  margin-top: var(--spacing-xxl);
  width: 100vw;
  padding: var(--spacing-lg) var(--spacing-xl);
  color: #fff;
  background: linear-gradient(var(--color-trans-dark), var(--color-trans-dark)),
    url(../../assets/images/analyst-board.png);
  background-size: cover;
}

@media only screen and (min-width: 1024px) {
  .banner {
    padding: var(--spacing-xxl) var(--spacing-xl);
    margin-top: var(--spacing-xxxl);
  }
}

@media only screen and (min-width: 1200px) {
  .banner {
    padding-left: calc(50vw - 600px + var(--spacing-xl));
  }
}
