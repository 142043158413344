.faq > .banner > h2 {
  text-transform: uppercase;
}

.faq .promo-banner h2:nth-of-type(1) {
  font-size: var(--font-default);
  margin-bottom: var(--spacing-md);
}

@media only screen and (min-width: 1024px) {
  .faq .promo-banner h2:nth-of-type(1) {
    font-size: var(--font-mobile-header);
  }
}
