.workUs {
  display: flex;
  flex-wrap: wrap;
  background-color: white;
  padding: var(--spacing-xl);
}

.workUs .article {
  width: 100%;
  padding: var(--spacing-xl) 0 var(--spacing-xxl) 0;
}

.workUs .article p:first-of-type {
  border-left: var(--border-width-sm) solid;
}

@media only screen and (min-width: 1200px) {
  .workUs {
    padding: var(--spacing-xxxl) calc(50vw - 600px + var(--spacing-xl));
  }
}
