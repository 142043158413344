.dot-button {
  min-width: 1em;
  min-height: 1em;
  border-radius: 50%;
  border: none;
  cursor: pointer;
}

.dot-button:hover {
  transition: background-color 0.3s;
}
