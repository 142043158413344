.form > .field-section {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-sm);
}

.form fieldset legend {
  color: #000;
}

.form fieldset {
  display: flex;
  flex-direction: column;
  border: none;
  row-gap: var(--spacing-sm);
  margin: var(--spacing-sm) 0;
}

.radio-fieldset {
  padding: 30px 0 0 10px;
}

.form .radio-fieldset::after {
  content: "*";
  position: absolute;
  transform: translate(-10px, -15px);
}

.form label {
  display: block;
  color: black;
}

.form > .input-container {
  text-align: center;
}

.form fieldset .radio-container {
  display: flex;
}

.form fieldset .radio-container > input {
  width: auto;
  appearance: none;
  width: var(--font-default);
  height: var(--font-default);
  border: var(--border-width-sm) solid var(--color-med-green);
  border-radius: 50%;
  padding: 2px;
  background-clip: content-box;
  margin-right: var(--spacing-sm);
}

.form fieldset .radio-container > input:checked {
  background-color: var(--color-med-green);
}

.form .alert {
  position: fixed;
  padding: var(--spacing-lg);
  top: 50vh;
  left: 50vw;
  background-color: var(--color-med-green);
  color: #fff;
  box-shadow: 0 0 3px #000;
  transform: translate(-50%, -50%);
}

.form input {
  width: 100%;
  height: var(--spacing-lg);
  border: var(--border-width-sm) solid var(--color-med-green);
}

.form textarea {
  border: var(--border-width-sm) solid var(--color-med-green);
  width: 100%;
}

.form .doc-buttons {
  margin-top: var(--spacing-sm);
}

.form .doc-buttons button {
  display: block;
  margin-bottom: var(--spacing-sm);
  background-color: #fff;
  color: var(--color-med-green);
  border: solid var(--border-width-sm) var(--color-med-green);
}

.form .doc-buttons button:hover {
  background-color: var(--color-med-green);
  color: #fff;
  border: solid var(--border-width-sm) #fff;
}

.form > .submit {
  height: auto;
  width: fit-content;
  font-size: var(--font-button);
  text-transform: uppercase;
  cursor: pointer;
  padding: 1em;
  background-color: var(--color-med-green);
  color: #fff;
}

.form > .submit:hover {
  transition: background-color 0.3s, color 0.3s;
  background-color: #fff;
  color: var(--color-med-green);
}

.form .file-selector {
  display: flex;
  align-items: center;
  margin-bottom: var(--spacing-sm);
}

.form .file-selector > label {
  z-index: 10;
  white-space: noWrap;
  display: block;
  background-color: #fff;
  color: var(--color-med-green);
  border: solid var(--border-width-sm) var(--color-med-green);
  font-size: var(--font-button);
  text-transform: uppercase;
  cursor: pointer;
  padding: var(--spacing-sm);
  margin-right: var(--spacing-sm);
}

.form .file-selector > label:hover {
  background-color: var(--color-med-green);
  color: #fff;
  border: solid var(--border-width-sm) #fff;
  transition: background-color 0.3s;
}

.form .file-selector input {
  display: none;
}

.form .file-selector > div {
  overflow: hidden;
  padding: var(--spacing-sm);
  word-break: break-all;
}

@media only screen and (min-width: 1024px) {
  .form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing-md);
    width: 100%;
  }

  .form > .field-section {
    grid-column: span 1;
    grid-row: span 2;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 10px;
    align-items: flex-start;
  }

  .form fieldset .input-container {
    width: 1fr;
  }

  .form > .input-container {
    grid-column: span 1;
    text-align: left;
  }

  .form > fieldset {
    grid-column: 2 / span 1;
    justify-self: left;
  }

  .form .doc-buttons {
    width: 100%;
    grid-column: span 2;
  }

  .form .doc-buttons button {
    max-width: none;
  }

  .form > .comment {
    text-align: left;
    grid-column: span 1;
  }

  .form > .submit {
    grid-column: 2;
    margin: 0;
  }

  .form textarea {
    max-width: inherit;
  }
}
