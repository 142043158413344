.logo-container a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
}

.logo-container img {
  width: var(--size-abs-lg);
}

.logo-container h2 {
  font-weight: 300;
  text-transform: uppercase;
}

@media only screen and (min-width: 1024px) {
  .logo-container a:hover {
    transition: color 300ms;
    color: var(--color-grey-mid);
  }
}
