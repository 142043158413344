.partner .features .gridContainer .feature {
  background-color: var(--color-med-green);
  color: #fff;
}

.partner .features > h2 {
  color: var(--color-med-green);
}

.partner .features {
  background: #fff;
}

.partner .promo-banner h2:nth-of-type(1) {
  font-size: var(--font-default);
  margin-bottom: var(--spacing-md);
}

@media only screen and (min-width: 1024px) {
  .partner {
    display: flex;
    flex-wrap: wrap;
  }

  .partner > article {
    width: 50%;
  }

  .partner > .image {
    width: 50%;
    background-size: cover;
    background-position: center;
  }

  .partner .promo-banner h2:nth-of-type(2) {
    font-size: var(--spacing-xl);
  }

  .partner .promo-banner h2:nth-of-type(1) {
    font-size: var(--font-mobile-header);
  }
}

@media only screen and (min-width: 1200px) {
  .partner > article:nth-of-type(1) {
    padding-right: calc(50vw - 600px + var(--spacing-xl));
  }

  .partner > article:nth-of-type(2) {
    padding-left: calc(50vw - 600px + var(--spacing-xl));
  }
}
