.nav-menu {
  display: flex;
  flex-direction: column;
  list-style: none;
}

.menu-icon {
  cursor: pointer;
}

.nav-container a {
  text-decoration: none;
}

@media only screen and (min-width: 1024px) {
  .nav-container > .nav-menu {
    flex-direction: row;
    justify-content: space-around;
  }

  .nav-menu li {
    padding: 0;
  }
}
