* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
}

body {
  background-color: black;
  font-size: var(--font-default);
}

.display-none {
  display: none;
}

.display-flex {
  display: flex;
}

.element-collapsed {
  height: 0;
}

.element-invisible {
  opacity: 0;
}

.App {
  color: var(--color-text-light);
  overflow: hidden;
}

section {
  margin: auto;
}

h2 {
  font-size: var(--font-mobile-header);
  font-weight: 700;
  overflow-wrap: break-word;
}

.back-white {
  background-color: #fff;
  color: var(--color-med-dark);
}

.back-white h2 {
  color: var(--color-med-green);
}

.back-green {
  background-color: var(--color-med-green);
  color: #fff;
}

.back-dark {
  background-color: var(--color-med-dark);
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  h2 {
    font-size: var(--font-desk-header);
  }
}

@media only screen and (min-width: 1200px) {
  section {
    padding: var(--spacing-lg) calc(50vw - 600px);
  }
}

@keyframes collapse {
  to {
    height: 0;
    padding: 0;
  }
}

@keyframes transition {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
