.features {
  background: linear-gradient(var(--color-trans-dark), var(--color-trans-dark)),
    url(../../assets/images/desk-consult-3.png);
  background-size: cover;
}

.features > h2 {
  text-transform: uppercase;
  font-weight: 900;
  text-align: center;
  color: white;
  padding: var(--spacing-lg) 0 var(--spacing-sm) 0;
}

.features .gridContainer {
  column-count: 1;
  gap: var(--spacing-md);
  padding: var(--spacing-lg);
}

.features .gridContainer .feature {
  break-inside: avoid;
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
  background-color: var(--color-trans-light);
}

@media only screen and (min-width: 480px) {
  .features .gridContainer {
    column-count: 2;
  }
}

@media only screen and (min-width: 1024px) {
  .features .gridContainer {
    column-count: 3;
  }

  .features .gridContainer {
    padding: var(--spacing-xl);
  }
}

@media only screen and (min-width: 1200px) {
}
