.news {
  margin-top: var(--spacing-xxl);
  padding: var(--spacing-xl);
  background-color: #fff;
}

.news figure img {
  width: 100%;
}

.news .byline {
  padding-top: var(--spacing-xl);
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 1024px) {
  .news {
    margin-top: var(--spacing-xxxl);
    padding: var(--spacing-xxxl) calc(50vw - 400px + var(--spacing-xl));
  }
}
