.exaworks article:nth-of-type(1),
.exaworks article:nth-of-type(2) {
  background-color: #fff;
  color: var(--color-med-dark);
}

.exaworks article:nth-of-type(1) h2 {
  color: var(--color-med-green);
}

.exaworks article:nth-of-type(2n + 2) {
  background-color: var(--color-med-green);
  color: #fff;
}

.exaworks article:nth-of-type(2n + 3) {
  background-color: var(--color-med-dark);
  color: #fff;
}

@media only screen and (min-width: 1024px) {
  .exaworks {
    display: flex;
    flex-wrap: wrap;
  }

  .exaworks article:nth-of-type(1) h2,
  .exaworks article:nth-of-type(2) h2 {
    margin-bottom: var(--spacing-xxxl);
  }

  .exaworks article:nth-of-type(1) {
    border: none;
    width: 100%;
    text-align: center;
    padding: var(--spacing-xxxl);
  }

  .exaworks article:nth-of-type(1) p {
    border: none;
  }

  .exaworks article:nth-of-type(3) p {
    margin-top: var(--spacing-sm);
  }

  .exaworks > .article {
    padding: var(--spacing-xxxl) var(--spacing-xl);
    width: 50%;
  }

  .exaworks > .image {
    width: 50%;
    background-size: cover;
  }
}

@media only screen and (min-width: 1200px) {
  .exaworks .article:nth-of-type(2n + 2) {
    padding-left: calc(50vw - 600px + var(--spacing-xl));
  }

  .exaworks .article:nth-of-type(2n + 3) {
    padding-right: calc(50vw - 600px + var(--spacing-xl));
  }
}
