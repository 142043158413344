.footer > .footer-upper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  color: white;
  background-color: var(--color-med-dark);
  align-items: center;
}

.footer ul {
  list-style: none;
  line-height: var(--spacing-md);
}

.footer > .footer-upper > ul {
  grid-column: span 12;
  text-align: center;
  margin: var(--spacing-md) 0;
}

.footer a {
  text-decoration: none;
  color: white;
}

.footer a:hover {
  transition: color 0.5s;
  color: var(--color-grey-mid);
}

.footer .logo-container {
  grid-column: span 12;
  padding: var(--spacing-sm) 0 var(--spacing-sm) 0;
  justify-content: center;
}

.footer .logo-container h2 {
  display: none;
}

.footer > .logo-container img {
  width: 50px;
}

.footer .copyright {
  grid-column: span 12;
  padding: var(--spacing-sm) var(--spacing-xl);
  color: #fff;
  background-color: var(--color-med-green);
  font-weight: 200;
}

@media screen and (min-width: 1024px) {
  .footer .footer-upper {
    padding: 0 var(--spacing-xl);
  }

  .footer .logo-container {
    grid-column: span 3;
  }

  .footer .logo-container a {
    justify-content: left;
  }

  .footer .logo-container h2 {
    display: block;
    padding-left: 0.3em;
  }

  .footer > .logo-container img {
    width: 70px;
  }

  .footer > .footer-upper > ul {
    grid-column: span 3;
    text-align: left;
  }
}

@media only screen and (min-width: 1200px) {
  .footer {
    display: block;
  }

  .footer .footer-upper {
    padding: 0 calc(50vw - 600px + var(--spacing-xl));
  }

  .footer .logo-container {
    grid-column: span 3;
  }

  .footer > .footer-upper > ul {
    grid-column: span 3;
  }

  .footer .copyright {
    padding: var(--spacing-sm) calc(50vw - 600px + var(--spacing-xl));
  }
}
