.lang-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.lang-container .lang-title::after {
  display: inline-block;
  content: "\25bc";
  margin-left: 5px;
  transform: scale(0.8, 0.5);
}

.lang-container .lang-title {
  color: white;
  text-transform: uppercase;
  cursor: pointer;
}

.lang-container .lang-title:hover {
  transition: color 300ms;
  color: var(--color-grey-mid);
}

.lang-container .lang-menu {
  display: flex;
  position: absolute;
  list-style: none;
}

.lang-container .lang-menu-hidden {
  display: none;
}

.lang-container li {
  cursor: pointer;
}
