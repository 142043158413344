.accordion {
  margin: auto;
  width: 100%;
  background-color: #fff;
  padding: var(--spacing-xl);
}

.accordion h2 {
  color: var(--color-med-green);
  text-transform: uppercase;
}

.accordion-item {
  margin: auto;
  border-bottom: 2px solid var(--color-med-green);
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: var(--spacing-md) 0;
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
}

.accordion-title > img {
  width: var(--size-abs-sm);
  height: var(--size-abs-sm);
}

.accordion-title:hover {
  transition: background-color 0.5s;
  background-color: var(--color-grey-light);
}

.accordion-content {
  background-color: var(--color-grey-light);
  color: #000;
  padding: var(--spacing-md);
}

.accordion-content p {
  margin-top: var(--spacing-sm);
}

@media only screen and (min-width: 1024px) {
  .accordion h2 {
    font-size: var(--font-desk-header);
    margin-bottom: var(--spacing-lg);
  }
}

@media only screen and (min-width: 1200px) {
  .accordion {
    padding: var(--spacing-xl) calc(50vw - 600px + var(--spacing-xl));
  }
}
