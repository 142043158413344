.header {
  position: fixed;
  margin-top: 0;
  display: grid;
  grid-template-columns: auto 150px auto;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  height: var(--spacing-xxl);
  padding: 0 var(--spacing-xl);
  background-color: var(--color-med-dark);
  z-index: 40;
}

.header li {
  margin: var(--spacing-sm) var(--spacing-xl);
  width: auto;
}

.header li a,
.header li {
  transition: color 300ms, background-color 300ms;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}

.header .sub-container li a {
  transition: color 300ms, background-color 300ms;
  color: #fff;
  text-transform: capitalize;
  text-decoration: none;
}

.header li a:hover,
.header li:hover,
.header .sub-container span:hover {
  transition: color 300ms, background-color 300ms;
  color: var(--color-grey-light);
}

/* Logo styling */
.header .logo-container {
  justify-content: center;
}

.header .logo-container h2 {
  padding-left: 0.3em;
}

/*  Language and navigation styling */
.header .burger-menu,
.header .lang-menu {
  position: absolute;
  left: 0;
  top: var(--spacing-xxl);
  width: 50vw;
  min-width: 200px;
  height: 100vh;
  background-color: var(--color-med-green);
  list-style: none;
}

.header .burger-menu-bck,
.header .lang-menu-bck {
  content: "";
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  top: 0;
  left: 0;
  background-color: var(--color-med-dark);
  animation: fadeIn 0.5s forwards;
}

.header .lang-menu {
  animation: slide-to-right 0.5s forwards;
}

.header .burger-menu {
  animation: slide-to-left 0.5s ease-in-out;
}

.header .burger-container > .menu-icon {
  width: var(--size-abs-md);
}

/* burger submenu styling */
.header .sub-container {
  width: 100%;
  margin: 0;
}

.header .burger-container .sub-container .sub-menu {
  display: none;
}

.header .burger-container .sub-container .sub-menu-expanded {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffff;
  opacity: 0.6;
}

.header .sub-container span {
  margin: 0;
  padding: var(--spacing-sm) var(--spacing-xl);
  display: flex;
  flex-direction: row;
}

.header .sub-container .sub-title::after,
.header .sub-container .sub-title-expanded::after {
  content: "\203a";
  margin-left: var(--spacing-sm);
  transform: scaleY(2) translateY(-0.1em);
}

.header .sub-container .sub-title-expanded {
  transition: color 300ms, background-color 300ms;
  color: var(--color-med-dark);
  background-color: #fff;
}

.header .sub-container li {
  margin: var(--spacing-sm) 0 0 var(--spacing-xxl);
}

.header .sub-container li:last-of-type {
  margin-bottom: var(--spacing-sm);
}

.header .sub-container a {
  color: var(--color-grey-dark);
}

.header .sub-container a:hover {
  color: var(--color-grey-light);
}

/* Language menu styling  */
.header .lang-container .lang-menu {
  left: auto;
  right: 0;
  flex-direction: column;
  align-items: end;
  text-align: right;
}

.lang-container .lang-title::after {
  color: var(--color-med-green);
  transform: scale(1, 0.5);
}

@media only screen and (min-width: 1024px) {
  .header {
    grid-template-columns: auto auto auto;
    height: var(--spacing-xxxl);
    padding: 0 var(--spacing-xl);
  }

  .header .logo-container {
    grid-area: 1 / 1 / 2 / 2;
    justify-content: flex-start;
  }

  .header .logo-container img {
    width: 60px;
  }

  .header .logo-container h2 {
    padding-left: 0.3em;
  }

  .header .nav-menu li {
    min-width: auto;
    padding: 0;
    margin: 0;
  }

  /*  Language and navigation styling */
  .header .nav-menu,
  .header .lang-menu {
    animation: none;
    width: auto;
    min-width: auto;
    top: var(--spacing-xxxl);
  }

  .header .nav-menu::after,
  .header .lang-menu::before {
    content: none;
  }

  /* Nav menu styling */
  .header .nav-container .nav-menu {
    flex-direction: row;
    gap: 1.5rem;
    position: inherit;
    justify-content: center;
    background-color: transparent;
    height: auto;
  }

  .header .nav-container > .menu-icon {
    width: var(--size-abs-md);
  }

  .header .nav-menu > li:last-of-type {
    margin: 0;
  }

  /* Language menu styling  */
  .header .lang-container .lang-menu {
    left: auto;
    transform: translateX(0 - var(--spacing-xxxl));
    right: auto;
    height: auto;
  }

  /* Sub menu styling */
  .header .sub-container {
    width: auto;
  }

  .header .nav-menu .sub-container .sub-menu {
    position: absolute;
    top: var(--spacing-xxxl);
    background-color: var(--color-med-green);
    opacity: 1;
    list-style: none;
  }

  .header .nav-menu .sub-container .sub-title-expanded {
    color: #fff;
    background-color: transparent;
  }

  .header .sub-container a {
    color: #fff;
  }

  .header .nav-menu .sub-container .sub-title-expanded:hover {
    color: var(--color-grey-mid);
  }

  .header .nav-menu .sub-container .sub-title {
    margin: 0;
    padding: 0;
  }

  .header .nav-menu .sub-container .sub-title::after {
    content: none;
  }

  .header .nav-menu .sub-container li {
    margin: 0;
    padding: var(--spacing-sm);
  }
}

@media only screen and (min-width: 1200px) {
  .header {
    padding: 0 calc(50vw - 600px + var(--spacing-xl));
  }
}

@keyframes fadeIn {
  to {
    opacity: 0.9;
  }
}

@keyframes slide-to-left {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
@keyframes slide-to-right {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
