.consult .features .gridContainer .feature {
  background-color: var(--color-med-green);
  color: #fff;
}

.consult .features > h2 {
  color: var(--color-med-green);
}

.consult .features {
  background: #fff;
}

.consult .invitation {
  background: linear-gradient(var(--color-trans-dark), var(--color-trans-dark)),
    url(../../assets/images/desk-consult-2.png);
  color: #fff;
  background-size: cover;
  text-align: center;
}

.consult .promo-banner h2:nth-of-type(1) {
  font-size: var(--font-default);
  margin-bottom: var(--spacing-md);
}

@media only screen and (min-width: 1024px) {
  .consult {
    display: flex;
    flex-wrap: wrap;
  }

  .consult > article {
    width: 50%;
  }

  .consult .promo-banner h2:nth-of-type(1) {
    font-size: var(--font-mobile-header);
  }

  .consult .features .gridContainer {
    display: flex;
    justify-content: center;
  }

  .consult .features .gridContainer > article {
    width: 25%;
  }
}

@media only screen and (min-width: 1024px) {
  .consult > .image {
    width: 50%;
    background-size: cover;
  }
}

@media only screen and (min-width: 1200px) {
  .consult > article:nth-of-type(1) {
    padding-right: calc(50vw - 600px + var(--spacing-xl));
    min-height: 250px;
  }
}
