.promo-banner {
  color: #fff;
  background-size: cover;
  padding: var(--spacing-xl);
  text-align: center;
  width: 100%;
}

@media only screen and (min-width: 1200px) {
  .promo-banner {
    padding: var(--spacing-xl) calc(50vw - 600px + var(--spacing-xl));
  }
}
