.home {
  margin-top: var(--spacing-xxl);
  color: white;
}

.home .button,
.home .link {
  margin-top: var(--spacing-xl);
}

.home .link {
  display: inline-block;
  text-decoration: none;
  font-size: var(--spacing-sm);
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  padding: var(--spacing-sm);
}

.home .article .bold {
  font-weight: 700;
}

.home .consult-us .button,
.home .consult-us .link {
  background-color: var(--color-med-green);
  color: #fff;
}

.home .consult-us .button:hover,
.home .consult-us .link:hover {
  transition: background-color 0.3s, color 0.3s;
  background-color: #fff;
  color: var(--color-med-green);
}

.home .be-partner .button,
.home .be-partner .link {
  background-color: #fff;
  color: var(--color-med-green);
}

.home .be-partner .button:hover,
.home .be-partner .link:hover {
  transition: background-color 0.3s, color 0.3s;
  background-color: var(--color-med-dark);
  color: #fff;
}

.home .consult-us {
  background: linear-gradient(var(--color-trans-dark), var(--color-trans-dark)),
    url(../../assets/images/scan-image-1-right.png);
  background-size: cover;
}

.home > .show-container {
  background: linear-gradient(var(--color-trans-dark), var(--color-trans-dark)),
    url(../../assets/images/desk-consult-3-left.png);
}

@media only screen and (min-width: 1024px) {
  .home {
    margin-top: var(--spacing-xxxl);
    display: flex;
    flex-wrap: wrap;
  }

  .home > .show-container,
  .home > article {
    width: 50%;
  }

  .home > .image {
    width: 50%;
    background-size: cover;
  }
}

@media only screen and (min-width: 1200px) {
  .home > article:nth-of-type(1),
  .home > article:nth-of-type(4) {
    padding-left: calc(50vw - 600px + var(--spacing-xl));
  }

  .home > .show-container {
    padding-left: calc(50vw - 600px);
  }

  .home > article:nth-of-type(2),
  .home > article:nth-of-type(3) {
    padding-right: calc(50vw - 600px + var(--spacing-xl));
  }
}
