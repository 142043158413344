.feature h2 {
  font-size: var(--font-feature);
  text-transform: uppercase;
  font-weight: 900;
  word-spacing: 3px;
  padding-left: var(--spacing-sm);
  margin-bottom: var(--spacing-md);
}

.feature p {
  padding-left: var(--spacing-sm);
  color: white;
}
