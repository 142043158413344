.article {
  padding: var(--spacing-xl);
}

.article p {
  margin-top: var(--spacing-md);
  padding-left: var(--spacing-lg);
}

.article p:first-of-type {
  border-left: solid var(--border-width-sm);
}

@media only screen and (min-width: 1024px) {
  .article {
    padding: var(--spacing-xxxl) var(--spacing-xl);
  }

  .article h2 {
    font-size: var(--font-desk-header);
  }
}
