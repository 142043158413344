@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

:root {
  --font-family: "Roboto", sans-serif;

  --color-med-green: #159397;
  --color-med-green-light: #61c0c4;
  --color-med-dark: #222a35ff;

  --color-grey-mid: #888888;
  --color-grey-light: #c5c4c4;
  --color-grey-dark: #444444;

  --color-trans-light: #ffffff19;
  --color-trans-dark: #222a35e6;

  --spacing-sm: 1rem;
  --spacing-md: 2rem;
  --spacing-lg: 3rem;
  --spacing-xl: 4rem;
  --spacing-xxl: 5rem;
  --spacing-xxxl: 7rem;

  --font-button: 1rem;
  --font-default: 1.5rem;
  --font-feature: 1.3rem;
  --font-mobile-header: 2rem;
  --font-desk-header: 4rem;

  --size-abs-sm: 10px;
  --size-abs-md: 20px;
  --size-abs-lg: 40px;

  --border-width-sm: 1px;
  --border-width-md: 2px;
}

html {
  font-size: 62.5%;
}
