.slide-show {
  padding-bottom: var(--spacing-md);
  overflow: hidden;
}

.slide-container {
  display: flex;
  flex-direction: row;
}

.slide-container article {
  width: 100%;
}

.slide-show .dot-button {
  background-color: #fff;
}

.slide-show .dot-button:hover {
  background-color: var(--color-med-green);
}

.slide-show .slide-active {
  background-color: var(--color-med-green);
}

.slide-show .slide-buttons-container {
  display: flex;
  width: var(--spacing-xxl);
  justify-content: space-around;
  margin: auto;
}
