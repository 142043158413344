.about-telerad {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #000;
  color: var(--color-med-green);
}

.about-telerad .promo-banner p:first-of-type {
  margin: var(--spacing-xl) auto var(--spacing-md) auto;
  text-transform: none;
}

.about-telerad .promo-banner p:last-of-type {
  font-style: italic;
  text-transform: none;
}

@media only screen and (min-width: 1024px) {
  .about-telerad > article {
    width: 50%;
  }
  .about-telerad > .image {
    width: 50%;
    min-height: 400px;
    background-size: cover;
  }

  @media only screen and (min-width: 1200px) {
    .about-telerad > .back-white {
      padding-left: calc(50vw - 600px + var(--spacing-xl));
    }

    .about-telerad > .back-green {
      padding-right: calc(50vw - 600px + var(--spacing-xl));
    }
  }
}
