.ipa-banner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6rem;
  justify-content: space-between;
  background: #fff;
  padding: 2rem var(--spacing-xl);
  border-top: 1px solid var(--color-med-green);
  min-height: 100px;
}

.ipa-banner a,
.ipa-banner > .ipa-container {
  display: flex;
  align-items: center;
}

.ipa-banner img {
  max-width: 100%;
}

.ipa-banner > .ipa-container > img {
  max-height: 43%;
}

@media only screen and (min-width: 512px) {
  .ipa-banner {
    grid-template-columns: repeat(4, auto);
    grid-template-rows: auto;
    gap: 5rem;
  }

  .ipa-banner a,
  .ipa-banner > .ipa-container {
    max-width: 180px;
  }
}

@media only screen and (min-width: 1200px) {
  .ipa-banner {
    padding: 2rem calc(50vw - 600px + var(--spacing-xl));
  }
}
